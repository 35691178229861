//profilepanel.tsx
"use client";

import { useRef, useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { AvatarImage, Avatar } from "@/components/ui/avatar";
import { Badge } from "@/components/ui/badge";
import { useAuth } from "../../../lib/AuthContext";
import { motion } from "framer-motion";
import { Card, CardContent } from "@/components/ui/card";
import { useRouter } from "next/navigation";
import {
    Watch as WatchIcon,
    Heart as HeartIcon,
    ShoppingBag as ShoppingBagIcon,
    DollarSign as DollarSignIcon,
    Layers as LayersIcon,
    ArrowLeft as ArrowLeftIcon,
    X,
    Shield as ShieldIcon,
    Loader2,
    PlusIcon,
    SettingsIcon

} from "lucide-react";

interface ProfilePanelProps {
    onClose: () => void;
}






export function ProfilePanel({ onClose }: ProfilePanelProps) {
    const { user, logout } = useAuth();
    const router = useRouter();
    const panelRef = useRef<HTMLDivElement>(null);
    const [portfolioValue, setPortfolioValue] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(false);

    const handleAddWatchClick = () => {
        router.push('/portfolio?action=add');
        onClose();
    };


    const getFormattedName = (name?: string) => {
        if (!name) return '';
        return name.charAt(0).toUpperCase() + name.slice(1);
    };


    useEffect(() => {
        const fetchUserData = async () => {
            if (!user?.id) return;
            try {
                const response = await fetch('/api/user/profile', {
                    headers: { 'user-id': user.id }
                });
                const data = await response.json();
                user.profilePicture = data.profilePicture; // Update user object with latest profile pic
            } catch (error) {
                console.error('Error fetching profile:', error);
            }
        };

        fetchUserData();
    }, [user?.id]);


    useEffect(() => {
        const fetchPortfolioValue = async () => {
            if (!user?.id) return;

            setIsLoading(true);
            try {
                const response = await fetch('/api/user/portfolio-value', {
                    headers: {
                        'user-id': user.id,
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch portfolio value');
                }

                const data = await response.json();
                setPortfolioValue(data.totalValue);
            } catch (error) {
                console.error('Error fetching portfolio value:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchPortfolioValue();
    }, [user?.id]);

    const handleLogout = async () => {
        await fetch('/api/signout');
        localStorage.removeItem("user");
        logout();
        router.push('/auth');
    };

    const handleNavigation = (path: string) => {
        router.push(path);
        onClose();
    };

    return (
        <div className="fixed inset-0 isolate z-50 flex" style={{ isolation: 'isolate' }}>
            <div className="fixed inset-0 bg-black/60 backdrop-blur-sm" onClick={onClose} />
            <motion.div
                ref={panelRef}
                className="ml-auto h-screen w-[400px] flex flex-col bg-black text-white shadow-2xl z-50"
                style={{ isolation: 'isolate' }}
                initial={{ x: "100%" }}
                animate={{ x: 0 }}
                exit={{ x: "100%" }}
                transition={{ type: "spring", stiffness: 300, damping: 30 }}
            >
                <Card className="flex-1 flex flex-col bg-black border-none">
                    <CardContent className="flex-1 flex flex-col p-6 overflow-y-auto">
                        {/* Header with back and close buttons */}
                        <div className="flex justify-between items-center mb-8 sticky top-0 bg-black z-10 py-2">
                            <button
                                onClick={() => handleNavigation('/')}
                                className="flex items-center space-x-2 text-white/80 hover:text-blue-400 transition-all duration-300"
                            >
                                <ArrowLeftIcon className="h-5 w-5" />
                                <span className="text-sm font-medium">BACK</span>
                            </button>
                            <Button
                                variant="ghost"
                                onClick={onClose}
                                className="text-white/80 hover:text-blue-400 transition-all duration-300"
                            >
                                <X className="h-5 w-5" />
                            </Button>
                        </div>

                        {/* User info section */}
                        <div className="relative mb-10 p-6 rounded-2xl bg-gradient-to-br from-blue-500/10 via-transparent to-purple-500/10 border border-white/10">
                            <div className="flex items-start justify-between mb-4">
                                <div className="flex flex-col">
                                    <h2 className="text-2xl font-bold text-white mb-1">
                                        Hey {getFormattedName(user?.name)}.

                                    </h2>
                                    <p className="text-sm text-gray-400">{user?.email}</p>
                                </div>
                                <Avatar className="w-14 h-14 ring-2 ring-blue-500/50 ring-offset-2 ring-offset-black transition-all duration-300 hover:ring-blue-400">
                                    <AvatarImage
                                        alt="User avatar"
                                        src={user?.profilePicture || "/reddit.webp"}
                                        className="object-cover"
                                    />
                                </Avatar>
                            </div>


                            {/* Portfolio Value Card */}
                            <div className="bg-black p-4 rounded-xl border border-white/10">
                                <div className="flex items-center justify-between mb-2">
                                    <span className="text-sm text-gray-400">Portfolio Value</span>
                                    <Badge className="bg-blue-500/20 text-blue-300 px-3">
                                        VERIFIED
                                    </Badge>
                                </div>
                                <div className="flex items-center justify-between">
                                    {isLoading ? (
                                        <div className="flex items-center space-x-2">
                                            <Loader2 className="h-5 w-5 animate-spin text-blue-400" />
                                            <span className="text-gray-400">Calculating...</span>
                                        </div>
                                    ) : (
                                        <>
                                            <p className="text-3xl font-bold text-white">
                                                ${portfolioValue.toLocaleString()}
                                            </p>
                                            <Button
                                                onClick={handleAddWatchClick}
                                                className="ml-4 bg-blue-500/10 hover:bg-blue-500/20 text-blue-400 hover:text-blue-300 text-sm px-3 py-1 rounded-lg transition-all duration-300 flex items-center space-x-1"
                                                variant="ghost"
                                                size="sm"
                                            >
                                                <PlusIcon className="h-4 w-4" />
                                                <span>Add</span>
                                            </Button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>



                        {/* Navigation menu */}
                        <nav className="space-y-2.5 flex-1">
                            {[

                                { label: "Portfolio", icon: WatchIcon, path: "/portfolio" },
                                { label: "Dashboard", icon: LayersIcon, path: "/dashboard", badge: "NEW" },
                                // { label: "Wishlist", icon: HeartIcon, path: "/wishlist-saved", badge: "NEW" },
                                { label: "Purchases", icon: DollarSignIcon, path: "/purchases" },
                                { label: "Marketplace", icon: ShoppingBagIcon, path: "/marketplace" },
                                { label: "Settings", icon: SettingsIcon, path: "/settings" },
                            ].map((item) => (
                                <button
                                    key={item.label}
                                    onClick={() => handleNavigation(item.path)}
                                    className="flex items-center justify-between w-full p-4 rounded-xl bg-black hover:bg-blue-600/10 hover:shadow-lg hover:shadow-blue-500/10 border border-white/10 hover:border-blue-500/20 transition-all duration-300 group"
                                >
                                    <div className="flex items-center space-x-3">
                                        <item.icon className="h-5 w-5 text-gray-400 group-hover:text-blue-400" />
                                        <span className="text-gray-300 group-hover:text-white">
                                            {item.label}
                                        </span>
                                    </div>
                                    {item.badge && (
                                        <Badge className="bg-blue-500/20 text-blue-300">
                                            {item.badge}
                                        </Badge>
                                    )}
                                </button>
                            ))}
                        </nav>

                        {/* Logout section */}
                        <div className="mt-auto pt-6">
                            <div className="p-4 rounded-xl bg-red-500/10 border border-red-500/20 mb-4">
                                <div className="flex items-center space-x-3 mb-2">
                                    <ShieldIcon className="h-5 w-5 text-red-400" />
                                    <span className="text-red-300 font-medium">Security Notice</span>
                                </div>
                                <p className="text-sm text-gray-400">
                                    Remember to always keep your account secure and never share your credentials.
                                </p>
                            </div>
                            <Button
                                onClick={handleLogout}
                                className="w-full bg-red-500/10 text-red-400 hover:bg-red-500/20 hover:text-red-300 transition-all duration-300"
                            >
                                Logout
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </motion.div>
        </div>
    );
}