"use client";

import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { AvatarImage, Avatar } from "@/components/ui/avatar";
import Link from "next/link";
import { useAuth } from "../../../lib/AuthContext";
import { useRouter } from "next/navigation";
import { AnimatePresence } from "framer-motion";
import Image from "next/image";
import {
  Bell as BellIcon,
  ShoppingBag as ShoppingBagIcon,
} from "lucide-react";
import { ProfilePanel } from "./ProfilePanel";
import { useCart } from "../../../contexts/CartContext";

interface Navbar1Props {
  isSticky?: boolean;
  onProfilePanelChange?: (isOpen: boolean) => void;
}

export function Navbar1({ isSticky, onProfilePanelChange }: Navbar1Props) {
  const { user, updateUserProfile } = useAuth();
  const { cartItems } = useCart();
  const [isProfilePanelOpen, setIsProfilePanelOpen] = useState(false);
  const router = useRouter();

  console.log('Cart items:', cartItems); // Debug log

  const navigationItems = ["Marketplace", "Dashboard", "Portfolio", "About"];

  useEffect(() => {
    onProfilePanelChange?.(isProfilePanelOpen);
  }, [isProfilePanelOpen, onProfilePanelChange]);

  const CartBadge = () => {
    const badgeCount = cartItems.length;

    if (badgeCount === 0) return null;

    return (
      <span
        className="absolute -top-2 -right-2 bg-blue-600 text-white text-xs font-bold rounded-full h-5 w-5 flex items-center justify-center"
        key={badgeCount} // Add a key to help with proper re-rendering
      >
        {badgeCount}
      </span>
    );
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (!user?.id) return;
      try {
        const response = await fetch('/api/user/profile', {
          headers: { 'user-id': user.id }
        });
        const data = await response.json();
        updateUserProfile({ profilePicture: data.profilePicture });
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };

    fetchUserData();
  }, [user?.id, updateUserProfile]);

  return (
    <nav className={`bg-black text-white border-b border-zinc-800 relative ${isSticky ? "sticky top-0 z-20" : ""}`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo - Hidden on mobile */}
          <div className="hidden md:flex w-[200px] items-center">
            <Link href="/" className="relative group">
              <div className="absolute -inset-1.5 bg-gradient-to-r from-blue-600 to-blue-400 rounded-full blur opacity-0 group-hover:opacity-20 transition duration-500"></div>
              <Image
                src="/watchinspect.svg"
                width={60}
                height={60}
                alt="Watch Inspect Logo"
                className="relative transform transition-transform duration-300 group-hover:scale-110"
                style={{
                  objectFit: "contain",
                  height: "auto"
                }}
              />
            </Link>
          </div>

          {/* Desktop Navigation - Centered, Hidden on mobile */}
          <div className="hidden md:flex flex-1 items-center justify-center">
            <div className="flex space-x-1">
              {navigationItems.map((item) => (
                <Link key={item} href={`/${item.toLowerCase()}`}>
                  <Button
                    variant="ghost"
                    className="text-white hover:text-blue-400 hover:bg-blue-500/10 hover:shadow-lg hover:shadow-blue-500/25 transition-all duration-300"
                  >
                    {item}
                  </Button>
                </Link>
              ))}
            </div>
          </div>

          {/* Mobile Layout - Logo and Actions */}
          <div className="flex md:hidden items-center justify-between w-full">
            {/* Mobile Logo */}
            <Link href="/" className="relative group">
              <div className="absolute -inset-1.5 bg-gradient-to-r from-blue-600 to-blue-400 rounded-full blur opacity-0 group-hover:opacity-20 transition duration-500"></div>
              <Image
                src="/watchinspect.svg"
                width={50}
                height={50}
                alt="Watch Inspect Logo"
                className="relative transform transition-transform duration-300 group-hover:scale-110"
                style={{
                  objectFit: "contain",
                  height: "auto"
                }}
              />
            </Link>

            {/* Mobile Actions */}
            <div className="flex items-center space-x-2">
              <Button
                variant="ghost"
                className="relative group hover:shadow-lg hover:shadow-blue-500/25 transition-all duration-300"
              >
                <BellIcon className="h-5 w-5 text-white group-hover:text-blue-400 transition-colors" />
              </Button>

              <Link href="/checkout">
                <Button
                  variant="ghost"
                  className="relative group hover:shadow-lg hover:shadow-blue-500/25 transition-all duration-300"
                >
                  <ShoppingBagIcon className="h-5 w-5 text-white group-hover:text-blue-400 transition-colors" />
                  <CartBadge />
                </Button>
              </Link>

              {user ? (
                <div className="relative">
                  <Button
                    variant="ghost"
                    onClick={() => setIsProfilePanelOpen(!isProfilePanelOpen)}
                    className="group hover:shadow-lg hover:shadow-blue-500/25 transition-all duration-300"
                  >
                    <Avatar className="h-8 w-8 ring-2 ring-blue-500/50 ring-offset-2 ring-offset-black transition-all duration-300 group-hover:ring-blue-400">
                      <AvatarImage
                        alt="User avatar"
                        src={user?.profilePicture || "/reddit.webp"}
                        className="object-cover"
                      />
                    </Avatar>
                  </Button>
                  <AnimatePresence>
                    {isProfilePanelOpen && (
                      <div className="absolute right-0 mt-2 z-50">
                        <ProfilePanel onClose={() => setIsProfilePanelOpen(false)} />
                      </div>
                    )}
                  </AnimatePresence>
                </div>
              ) : (
                <Link href="/auth">
                  <Button
                    size="sm"
                    className="bg-blue-600 hover:bg-blue-700 hover:shadow-lg hover:shadow-blue-500/25 transition-all duration-300"
                  >
                    Login
                  </Button>
                </Link>
              )}
            </div>
          </div>

          {/* Desktop Right Section */}
          <div className="hidden md:flex w-[200px] items-center justify-end space-x-4">
            <Button
              variant="ghost"
              className="relative group hover:shadow-lg hover:shadow-blue-500/25 transition-all duration-300"
            >
              <BellIcon className="h-5 w-5 text-white group-hover:text-blue-400 transition-colors" />
            </Button>

            <Link href="/checkout">
              <Button
                variant="ghost"
                className="relative group hover:shadow-lg hover:shadow-blue-500/25 transition-all duration-300"
              >
                <ShoppingBagIcon className="h-5 w-5 text-white group-hover:text-blue-400 transition-colors" />
                <CartBadge />
              </Button>
            </Link>

            {user ? (
              <div className="relative">
                <Button
                  variant="ghost"
                  onClick={() => setIsProfilePanelOpen(!isProfilePanelOpen)}
                  className="group hover:shadow-lg hover:shadow-blue-500/25 transition-all duration-300"
                >
                  <Avatar className="ring-2 ring-blue-500/50 ring-offset-2 ring-offset-black transition-all duration-300 group-hover:ring-blue-400">
                    <AvatarImage
                      alt="User avatar"
                      src={user.profilePicture || "/reddit.webp"}
                      className="object-cover aspect-square w-full h-full"
                    />
                  </Avatar>
                </Button>
                <AnimatePresence>
                  {isProfilePanelOpen && (
                    <div className="absolute right-0 mt-2 z-50">
                      <ProfilePanel onClose={() => setIsProfilePanelOpen(false)} />
                    </div>
                  )}
                </AnimatePresence>
              </div>
            ) : (
              <Link href="/auth">
                <Button
                  className="bg-blue-600 hover:bg-blue-700 hover:shadow-lg hover:shadow-blue-500/25 transition-all duration-300"
                >
                  Login
                </Button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}