//components/component/portfolio/TradingViewChart.tsx
import React, { useEffect, useRef } from 'react';
import { createChart, IChartApi } from 'lightweight-charts';

interface OHLCData {
    time: number;
    open: number;
    high: number;
    low: number;
    close: number;
    volume: number;
}

interface TradingViewChartProps {
    data?: OHLCData[];
    height?: number;
}

const TradingViewChart: React.FC<TradingViewChartProps> = ({ data = [], height = 400 }) => {
    const chartContainerRef = useRef<HTMLDivElement>(null);
    const chartRef = useRef<IChartApi | null>(null);

    useEffect(() => {
        if (!chartContainerRef.current || !data?.length) return;

        // Create chart instance with dark theme and blue highlights
        const chartOptions = {
            height: height,
            layout: {
                background: { color: '#0F172A' },
                textColor: '#D1D5DB',
            },
            grid: {
                vertLines: { visible: false },
                horzLines: { visible: false },
            },
            rightPriceScale: {
                borderVisible: false,
                textColor: '#D1D5DB',
            },
            timeScale: {
                borderVisible: false,
                textColor: '#D1D5DB',
                timeVisible: true,
                secondsVisible: false,
            },
            crosshair: {
                horzLine: {
                    visible: true,
                    labelVisible: true,
                    color: '#475569',
                    labelBackgroundColor: '#475569',
                },
                vertLine: {
                    visible: true,
                    labelVisible: true,
                    color: '#475569',
                    labelBackgroundColor: '#475569',
                },
            },
        };

        chartRef.current = createChart(chartContainerRef.current, chartOptions);

        // Create area series with blue colors instead of purple
        const areaSeries = chartRef.current.addAreaSeries({
            lineColor: '#3B82F6', // Changed to blue
            topColor: 'rgba(59, 130, 246, 0.4)', // Changed to blue with opacity
            bottomColor: 'rgba(59, 130, 246, 0.0)', // Changed to blue with zero opacity
            lineWidth: 2,
            priceLineVisible: false,
            crosshairMarkerVisible: true,
            crosshairMarkerRadius: 4,
            crosshairMarkerBorderColor: '#3B82F6', // Changed to blue
            crosshairMarkerBackgroundColor: '#3B82F6', // Changed to blue
            lastValueVisible: true,
        });

        // ... (keep the rest of the chart setup code)

        // Process and format the data
        const processedData = [...data]
            .sort((a, b) => a.time - b.time)
            .reduce((map, item) => {
                const dateStr = new Date(item.time).toISOString().split('T')[0];
                map.set(dateStr, {
                    time: dateStr,
                    value: item.close,
                });
                return map;
            }, new Map());

        // Convert Map back to array
        const formattedData = Array.from(processedData.values());

        // Set the data
        areaSeries.setData(formattedData);

        // Fit content
        chartRef.current.timeScale().fitContent();

        // Handle resize
        const handleResize = () => {
            if (chartRef.current && chartContainerRef.current) {
                chartRef.current.applyOptions({
                    width: chartContainerRef.current.clientWidth,
                });
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
            if (chartRef.current) {
                chartRef.current.remove();
            }
        };
    }, [data, height]);

    return (
        <div className="w-full h-full bg-slate-900 rounded-lg">
            <div ref={chartContainerRef} className="w-full" />
        </div>
    );
};

export default TradingViewChart;