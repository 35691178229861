
// src/components/component/support-main.tsx

// SupportMain.tsx
import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';
import { MessengerInterface } from './messenger-interface';
import axios from "axios";
import Image from 'next/image';

export interface Message {
  id: number;
  text: string;
  userId: string;
  userName: string;
  sender: 'user' | 'admin';
}

interface SupportMainProps {
  onClose: () => void;
}

export function SupportMain({ onClose }: SupportMainProps) {
  const [showMessenger, setShowMessenger] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const handleSendMessageToServer = async (message: Message) => {
    try {
      const response = await axios.post("/api/support/messages", message);
    } catch (error) {
      console.error("Error sending message to the server:", error);
    }
  };

  return (
    <div className={`fixed ${isMobile ? 'inset-x-0 bottom-0' : 'top-1/2 right-8 transform -translate-y-1/2'} z-50`}>
      <motion.div
        key="1"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ duration: 0.3 }}
        className={`flex flex-col bg-white shadow-lg overflow-hidden ${isMobile ? 'w-full rounded-t-2xl' : 'w-[400px] rounded-2xl'}`}
        style={isMobile ? {
          maxHeight: 'calc(100vh - env(safe-area-inset-bottom))',
          height: 'calc(100vh - 100px)' // Adjust this value as needed
        } : undefined}
      >
        {showMessenger ? (
          <MessengerInterface
            onBack={() => setShowMessenger(false)}
            onMessageSend={handleSendMessageToServer}
          />
        ) : (
          <div className="flex flex-col h-full">
            <div className="bg-[#1e293b] p-4 flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <Image
                  alt="WATCH INSPECT logo"
                  className="h-8 w-8"
                  height="40"
                  src="/512white.png"
                  style={{
                    aspectRatio: "40/40",
                    objectFit: "cover",
                  }}
                  width="40"
                />
                <span className="text-white font-semibold text-sm">WATCH INSPECT</span>
              </div>
              <div className="flex items-center space-x-6">
                <div className="relative">
                  <span className="absolute -top-0 -right-0 h-3 w-3 rounded-full bg-green-500" />
                  <Image
                    alt="Profile Picture"
                    className="h-10 w-10 rounded-full"
                    height="50"
                    src="/tristan_HEADSHOT.jpg"
                    style={{
                      aspectRatio: "50/50",
                      objectFit: "cover",
                    }}
                    width="50"
                  />
                </div>
                <button aria-label="Close" onClick={onClose}>
                  <XIcon className="text-white h-6 w-6" />
                </button>
              </div>
            </div>

            <div className="bg-[#0f172a] p-4 text-center">
              <h2 className="text-2xl font-bold text-white mb-1">Hi there 👋</h2>
              <p className="text-xl text-white">How can we help?</p>
              <p className="text-xs text-white">More features to come, you can message us directly below.</p>
            </div>

            <div className="flex-1 overflow-y-auto">
              <div className="p-4 blur-sm">
                <div className="mt-5 space-y-4 rounded-md border p-4">
                  <div className="flex items-center p-2 bg-gray-100 rounded">
                    <input
                      className="p-2 w-full bg-transparent focus:outline-none"
                      placeholder="Search for help"
                      type="search"
                    />
                    <MicroscopeIcon className="text-gray-400 h-5 w-5" />
                  </div>
                  <div className="flex justify-between items-center">
                    <span>Watch valuation</span>
                    <ChevronRightIcon className="text-gray-400 h-5 w-5" />
                  </div>
                  <div className="flex justify-between items-center">
                    <span>Authenticity check</span>
                    <ChevronRightIcon className="text-gray-400 h-5 w-5" />
                  </div>
                  <div className="flex justify-between items-center">
                    <span>Market price tracking</span>
                    <ChevronRightIcon className="text-gray-400 h-5 w-5" />
                  </div>
                  <div className="flex justify-between items-center">
                    <span>Latest watch releases</span>
                    <ChevronRightIcon className="text-gray-400 h-5 w-5" />
                  </div>
                  <div className="flex justify-between items-center">
                    <span>Marketplace updates (6th April 2023)</span>
                    <ChevronRightIcon className="text-gray-400 h-5 w-5" />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-auto">
              <button
                className="bg-gray-100 p-4 flex justify-between items-center w-full text-left hover:bg-gray-200"
                onClick={() => setShowMessenger(true)}
              >
                <div>
                  <span className="text-lg font-semibold">Send us a message</span>
                  <p className="text-sm text-gray-500">We typically reply in a few minutes</p>
                </div>
                <ChevronRightIcon className="text-gray-400 h-6 w-6" />
              </button>

              <div className="border-t p-4 flex justify-around bg-gray-50">
                <div className="flex flex-col items-center">
                  <button aria-label="Home">
                    <HomeIcon className="text-gray-600 h-6 w-6" />
                  </button>
                  <span className="text-xs text-gray-600 mt-1">Home</span>
                </div>
                <div className="flex flex-col items-center">
                  <button aria-label="Help">
                    <FileQuestionIcon className="text-gray-600 h-6 w-6" />
                  </button>
                  <span className="text-xs text-gray-600 mt-1">Help</span>
                </div>
                <div className="flex flex-col items-center">
                  <button aria-label="Messages">
                    <TextIcon className="text-gray-600 h-6 w-6" />
                  </button>
                  <span className="text-xs text-gray-600 mt-1">Messages</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </motion.div>
    </div>
  );
}


function ChevronRightIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m9 18 6-6-6-6" />
    </svg>
  )
}


function FileQuestionIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
      <path d="M10 10.3c.2-.4.5-.8.9-1a2.1 2.1 0 0 1 2.6.4c.3.4.5.8.5 1.3 0 1.3-2 2-2 2" />
      <path d="M12 17h.01" />
    </svg>
  )
}


function HomeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
      <polyline points="9 22 9 12 15 12 15 22" />
    </svg>
  )
}


function MicroscopeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M6 18h8" />
      <path d="M3 22h18" />
      <path d="M14 22a7 7 0 1 0 0-14h-1" />
      <path d="M9 14h2" />
      <path d="M9 12a2 2 0 0 1-2-2V6h6v4a2 2 0 0 1-2 2Z" />
      <path d="M12 6V3a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v3" />
    </svg>
  )
}


function TextIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M17 6.1H3" />
      <path d="M21 12.1H3" />
      <path d="M15.1 18H3" />
    </svg>
  )
}


function XIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M18 6 6 18" />
      <path d="m6 6 12 12" />
    </svg>
  )
}


function ChevronLeftIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m15 18-6-6 6-6" />
    </svg>
  );
}