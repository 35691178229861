// this is for user - not the admin, the user will message through here.
// src/components/component/messenger-interface.tsx
// MessengerInterface.tsx
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Image from 'next/image';

interface Message {
    id: number;
    text: string;
    userId: string;
    userName: string;
    sender: 'user' | 'admin';
    adminResponses?: Message[];
}

interface MessengerInterfaceProps {
    onBack: () => void;
    onMessageSend: (message: Message) => Promise<void>;
}

export function MessengerInterface({ onBack, onMessageSend }: MessengerInterfaceProps) {
    const [messages, setMessages] = useState<Message[]>([]);
    const [inputText, setInputText] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const messagesEndRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth < 768);
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    const handleSendMessage = async () => {
        if (inputText.trim() !== '') {
            const user = JSON.parse(localStorage.getItem('user') || '{}');
            const newMessage: Message = {
                id: messages.length + 1,
                text: inputText,
                sender: 'user',
                userId: user.id,
                userName: user.name,
            };
            setMessages([...messages, newMessage]);
            setInputText('');
            await onMessageSend(newMessage);
        }
    };

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        setIsLoggedIn(!!user.id);

        if (user.id) {
            fetchMessages();
            const intervalId = setInterval(fetchMessages, 5000);
            return () => clearInterval(intervalId);
        }
    }, []);

    const fetchMessages = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user') || '{}');
            const response = await axios.get(`/api/support/messages/${user.id}`);
            setMessages(response.data);
        } catch (error) {
            console.error("Error fetching messages:", error);
        }
    };

    if (!isLoggedIn) {
        return (
            <div className={`flex flex-col ${isMobile ? 'h-[70vh]' : 'h-[510px]'} items-center justify-center`}>
                <div className="absolute inset-0 bg-gray-500 opacity-50 blur" />
                <div className="z-10">
                    <button
                        className="bg-blue-500 text-white px-6 py-3 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        onClick={() => window.location.href = 'https://watchinspect.com/auth'}
                    >
                        Login to Send Messages
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className={`flex flex-col ${isMobile ? 'h-[90vh]' : 'h-[510px]'}`}>
            <div className="bg-[#1e293b] p-4 flex items-center justify-between">
                <button onClick={onBack}>
                    <ChevronLeftIcon className="text-white h-6 w-6" />
                </button>
                <div className="flex items-center space-x-2">
                    <Image
                        alt="WATCH INSPECT logo"
                        className="h-8 w-8"
                        height="40"
                        src="/512white.png"
                        style={{
                            aspectRatio: "40/40",
                            objectFit: "cover",
                        }}
                        width="40"
                    />
                    <span className="text-white font-semibold text-sm">WATCH INSPECT</span>
                </div>
                <div className="relative">
                    <span className="absolute -top-0 -right-0 h-3 w-3 rounded-full bg-green-500" />
                    <Image
                        alt="Profile Picture"
                        className="h-10 w-10 rounded-full"
                        height="50"
                        src="/tristan_HEADSHOT.jpg"
                        style={{
                            aspectRatio: "50/50",
                            objectFit: "cover",
                        }}
                        width="50"
                    />
                </div>
            </div>
            <div className="flex-1 overflow-y-auto p-4">
                {messages.map((message, index) => (
                    <div key={index}>
                        <div className={`flex items-center ${message.sender === 'user' ? 'justify-end' : 'justify-start'}`}>
                            <div className={`${message.sender === 'user' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'} rounded-lg p-2 mb-2 max-w-[80%]`}>
                                {message.text}
                            </div>
                        </div>
                        {message.adminResponses && message.adminResponses.map((adminResponse, adminIndex) => (
                            <div key={adminIndex} className="flex justify-start">
                                <div className="bg-gray-200 text-gray-800 rounded-lg p-2 mb-2 max-w-[80%]">
                                    {adminResponse.text}
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
                <div ref={messagesEndRef} />
            </div>
            <div className="p-4 bg-white mt-auto">
                <div className="flex items-center space-x-2">
                    <input
                        className="flex-1 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Type your message..."
                        type="text"
                        value={inputText}
                        onChange={(e) => setInputText(e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                handleSendMessage();
                            }
                        }}
                    />
                    <button
                        className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        onClick={handleSendMessage}
                    >
                        Send
                    </button>
                </div>
            </div>
        </div>
    );
}

function ChevronLeftIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="m15 18-6-6 6-6" />
        </svg>
    );
}