// src/components/component/SupportButton.tsx
import React from 'react';

interface SupportButtonProps {
    onClick: () => void;
    isVisible: boolean;
}

export function SupportButton({ onClick, isVisible }: SupportButtonProps) {
    if (!isVisible) {
        return null;
    }
    return (
        <div className="fixed bottom-8 right-8 z-50">
            <span className="absolute -top-0 -right-0 h-4 w-4 rounded-full bg-green-500 animate-pulse" />

            <button
                className="bg-blue-500 text-white rounded-full p-4 shadow-lg"
                onClick={onClick}
            >
                <HeadphonesIcon />

            </button>
        </div>
    );
}

function HeadphonesIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M3 14h3a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-7a9 9 0 0 1 18 0v7a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3" />
        </svg>
    )
}